import styles from './AlertBanner.module.scss';
import { HashLink } from '../../lib/HashLink';

type AlertBannerProps = {
  name: string;
  href: string;
};

const Caret = (): JSX.Element => (
  <svg
    width="10"
    height="20"
    viewBox="0 0 9 15"
    className={styles.Caret}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.247.947l6.047 6.136-6.087 6.179.931.947L8.16 7.083 1.18 0z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

const AlertBanner = ({ name, href }: AlertBannerProps): JSX.Element => {
  return (
    <div className={styles.AlertBanner}>
      <h3 className={styles.message}>{name}</h3>
      <HashLink to={href} className={styles.link}>
        <div className={styles.UpdateContainer}>
          <p className={styles.update}>See update</p>
          <Caret />
        </div>
      </HashLink>
    </div>
  );
};

export default AlertBanner;
