/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import { useRef, useState, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { useDebounce } from 'use-debounce';

import Autocomplete from '../../../components/Autocomplete';
import AlertBanner from '../../../components/AlertBanner';

import useFormReducer from '../../../lib/useFormReducer';
import getObjectByType from '../../../lib/getObjectByType';

import styles from './SearchSection.module.scss';
import AlertBannerMultiProps from '../../../models/AlertBannerMultiProps';
import AlertBannerProps from '../../../models/AlertBannerProps';
import SubsectionParagraphProps from '../../../models/SubsectionParagraphProps';
import SearchProps from '../../../models/SearchProps';

const renderAlertBannerMultiData = (subsection: SubsectionParagraphProps): JSX.Element[] => {
  const alertBannerMulti = getObjectByType(
    subsection.data,
    'alert-banner-multi'
  ) as unknown as AlertBannerMultiProps;

  return alertBannerMulti.data.map(({ key, name, href }: AlertBannerProps) => (
    <AlertBanner key={key} name={name} href={href} />
  ));
};

const SearchSection = ({ searchTerm = '', subsection, hideAlert }: SearchProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const location = useLocation();
  const path = location.pathname.split('/')[1];

  const [styling, setStyling] = useState('');

  const [text, setText] = useState('');
  const [speed] = useState(100);
  const [placeholderText, setPlaceholderText] = useState('Search guidelines here');

  const handleTyping = (): void => {
    setText(placeholderText.substring(0, text.length + 1));
  };

  useEffect(() => {
    if (path === 'home') {
      const timer = setTimeout(handleTyping, speed);
      return () => clearTimeout(timer);
    }
    return undefined;
  });

  useEffect(() => {
    if (path === 'results' && searchTerm) {
      setStyling(styles.SearchResults);
      setText(searchTerm);
    } else if (path === 'home') {
      setStyling(styles.Home);
    } else {
      setStyling(styles.Detail);
      setText('Search here');
    }
  }, [searchTerm, path]);

  const [user, dispatchUser] = useFormReducer({
    searchTerm: { id: 'searchTerm', label: 'Search', value: '' },
  });
  const history = useHistory();

  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    if (inputRef.current) {
      inputRef.current.style.color = 'blue';
    }
    history.push(`/results/?s=${user.searchTerm.value}`);
  };

  const [value] = useDebounce(user.searchTerm.value, 1000);

  return (
    <div className={styles.SearchField}>
      <div className={styles.formWrapper}>
        <form onSubmit={handleSubmit}>
          <div className={styling}>
            <div className={styles.searchBar}>
              <svg xmlns="http://www.w3.org/2000/svg" height="21" viewBox="0 0 21 21" width="21">
                <clipPath id="a">
                  <path d="m0 0h21v21h-21z" />
                </clipPath>
                <g clipPath="url(#a)">
                  <path
                    d="m20.9883 20.0615-7.0391-7.0391c1.2156-1.4221 1.8815-3.23259 1.8771-5.1034-.0056-1.80635-.6277-3.55668-1.7633-4.96142-1.1356-1.40473-2.7168-2.379708-4.48186-2.763624-1.76509-.383915-3.60836-.1537677-5.22486.652369-1.61651.806135-2.90938 2.139955-3.66474 3.780805-.7553603 1.64084-.927942 3.49039-.489196 5.24265.438746 1.75232 1.462526 3.30222 2.901986 4.39352 1.43945 1.0913 3.20831 1.6585 5.01396 1.6078s3.53991-.7164 4.91581-1.8867l7.0391 7.0391zm-13.0693-5.5492c-1.30685 0-2.58433-.3877-3.67077-1.114-1.08645-.7263-1.93302-1.7585-2.43259-2.9661-.49957-1.20761-.62969-2.53626-.37388-3.81783s.88604-2.45845 1.81095-3.38171c.9249-.92326 2.1029-1.5514 3.38492-1.80493s2.61044-.12106 3.81717.38066c1.2067.50172 2.2374 1.35013 2.9618 2.43786.7243 1.08774 1.1098 2.3659 1.1074 3.67275 0 .86683-.1709 1.72516-.503 2.5259-.3321.8007-.8188 1.528-1.4322 2.1404-.6135.6124-1.3418 1.0978-2.143 1.4285-.80134.3306-1.65997.5-2.5268.4985z"
                    fill="#000"
                  />
                </g>
              </svg>
              <input
                className={styles.input}
                type="text"
                autoComplete="off"
                placeholder={text}
                name={user.searchTerm.id}
                value={user.searchTerm.value}
                required
                onFocus={() => setPlaceholderText('')}
                onChange={(event) =>
                  dispatchUser({
                    ...user.searchTerm,
                    value: event.target.value,
                  })
                }
              />
            </div>
          </div>
          <button className={styles.button} type="submit">
            Submit
          </button>
        </form>
      </div>
      {path === '' ? <Autocomplete searchTerm={value} /> : <></>}
      {!hideAlert && subsection !== undefined && (
        <>
          <div className={styles.guidanceWrapper}>
            <p className={styles.guidance}>New retail brand guidance</p>
            <div className={styles.alertWrapper}>
              {subsection?.map((subsectionParagraphProps: SubsectionParagraphProps) =>
                renderAlertBannerMultiData(subsectionParagraphProps)
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SearchSection;
