export const TypographyTokens = {
  "fontsize": {
    "body": {
      "12": {
        "value": "12px"
      },
      "14": {
        "value": "14px"
      },
      "16": {
        "value": "16px"
      }
    },
    "feature": {
      "40": {
        "value": "40px"
      },
      "48": {
        "value": "48px"
      },
      "64": {
        "value": "64px"
      },
      "80": {
        "value": "80px"
      },
      "96": {
        "value": "96px"
      },
      "128": {
        "value": "128px"
      },
      "144": {
        "value": "144px"
      },
      "superscript": {
        "value": "50%"
      }
    },
    "micro": {
      "11": {
        "value": "11px"
      }
    },
    "title": {
      "16": {
        "value": "16px"
      },
      "20": {
        "value": "20px"
      },
      "24": {
        "value": "24px"
      },
      "32": {
        "value": "32px"
      },
      "40": {
        "value": "40px"
      },
      "48": {
        "value": "48px"
      },
      "64": {
        "value": "64px"
      },
      "superscript": {
        "value": "50%"
      }
    }
  },
  "fontweight": {
    "light": {
      "value": 300
    },
    "regular": {
      "value": 400
    },
    "bold": {
      "value": 700
    }
  },
  "letterspacing": {
    "wide": {
      "value": "0.5px"
    },
    "semiwide": {
      "value": "0.25px"
    }
  },
  "lineheight": {
    "body": {
      "16": {
        "value": "16px"
      },
      "18": {
        "value": "18px"
      },
      "20": {
        "value": "20px"
      }
    },
    "feature": {
      "40": {
        "value": "40px"
      },
      "48": {
        "value": "48px"
      },
      "64": {
        "value": "64px"
      },
      "76": {
        "value": "76px"
      },
      "88": {
        "value": "88px"
      },
      "120": {
        "value": "120px"
      },
      "136": {
        "value": "136px"
      }
    },
    "micro": {
      "16": {
        "value": "16px"
      }
    },
    "title": {
      "20": {
        "value": "20px"
      },
      "24": {
        "value": "24px"
      },
      "28": {
        "value": "28px"
      },
      "36": {
        "value": "36px"
      },
      "40": {
        "value": "40px"
      },
      "48": {
        "value": "48px"
      },
      "64": {
        "value": "64px"
      }
    }
  }
}