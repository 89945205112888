import GuideLineListProps from '../models/GuideLineListProps';
import PageProps from '../models/PageProps';
import ImageProps from '../models/ImageProps';
import TextMultiProps from '../models/TextMultiProps';
import TextProps from '../models/TextProps';
import UrlLinkMultiProps from '../models/UrlLinkMultiProps';

import getObjectByType from './getObjectByType';
import ComponentRouteProps from '../models/ComponentRouteProps';

// Okay to hard-code here since component name should not change.
// Use it elsewhere for mobile rendering.
const guidelinesComponent = 'GuidelinesSection';

const getGuidelinesList = (page: PageProps): GuideLineListProps[] | [] => {
  const guidelinesList = page.section
    ?.filter((section) => section.component === guidelinesComponent)[0]
    .subsection?.map((item) => {
      const title = getObjectByType(item.data, 'text') as unknown as TextProps;
      const description = getObjectByType(item.data, 'text-multi') as TextMultiProps;
      const image = getObjectByType(item.data, 'image') as unknown as ImageProps;
      const links = getObjectByType(item.data, 'url-link-multi') as UrlLinkMultiProps;
      const route = getObjectByType(item.data, 'route') as unknown as ComponentRouteProps;
      return { title, description, image, links, route };
    });

  return guidelinesList || [];
};

export default getGuidelinesList;
