export const ColorTokens = {
  "palette": {
    "black": {
      "value": "#000000"
    },
    "white": {
      "value": "#ffffff"
    },
    "red": {
      "value": "#ee0000"
    },
    "gray95": {
      "value": "#f6f6f6"
    },
    "gray85": {
      "value": "#d8dada"
    },
    "gray65": {
      "value": "#a7a7a7"
    },
    "gray44": {
      "value": "#6f7171"
    },
    "gray20": {
      "value": "#333333"
    },
    "gray11": {
      "value": "#1b1d1f"
    },
    "orange94": {
      "value": "#ffece0"
    },
    "orange83": {
      "value": "#ffcaaa"
    },
    "orange71": {
      "value": "#ffa46d"
    },
    "orange58": {
      "value": "#ff8027"
    },
    "orange41": {
      "value": "#b95319"
    },
    "orange24": {
      "value": "#732706"
    },
    "orange17": {
      "value": "#561701"
    },
    "yellow94": {
      "value": "#fff9de"
    },
    "yellow87": {
      "value": "#fff4bc"
    },
    "yellow74": {
      "value": "#ffe97a"
    },
    "yellow53": {
      "value": "#fed60e"
    },
    "yellow39": {
      "value": "#bc9f0a"
    },
    "yellow20": {
      "value": "#635305"
    },
    "yellow15": {
      "value": "#4b3f04"
    },
    "blue94": {
      "value": "#e3f2fd"
    },
    "blue82": {
      "value": "#aad8f9"
    },
    "blue62": {
      "value": "#4aabf2"
    },
    "blue46": {
      "value": "#0089ec"
    },
    "blue38": {
      "value": "#006fc1"
    },
    "blue21": {
      "value": "#003e6c"
    },
    "blue15": {
      "value": "#002c4d"
    },
    "green91": {
      "value": "#dcf5e6"
    },
    "green77": {
      "value": "#a4e6bd"
    },
    "green61": {
      "value": "#63d48e"
    },
    "green36": {
      "value": "#00b845"
    },
    "green26": {
      "value": "#008331"
    },
    "green15": {
      "value": "#004b1c"
    },
    "green10": {
      "value": "#003514"
    },
    "pink87": {
      "value": "#febfe8"
    },
    "pink76": {
      "value": "#fc89d5"
    },
    "pink62": {
      "value": "#fb42bc"
    },
    "pink46": {
      "value": "#b9318b"
    },
    "pink25": {
      "value": "#671b4e"
    },
    "purple85": {
      "value": "#edb9fb"
    },
    "purple75": {
      "value": "#e084f9"
    },
    "purple60": {
      "value": "#ce3df5"
    },
    "purple39": {
      "value": "#84279e"
    },
    "purple20": {
      "value": "#461553"
    }
  },
  "background": {
    "primary": {
      "light": {
        "value": "#ffffff"
      },
      "dark": {
        "value": "#000000"
      },
      "inverse": {
        "light": {
          "value": "#000000"
        },
        "dark": {
          "value": "#ffffff"
        }
      }
    },
    "secondary": {
      "light": {
        "value": "#f6f6f6"
      },
      "dark": {
        "value": "#1b1d1f"
      }
    },
    "brandhighlight": {
      "value": "#ee0000"
    }
  },
  "feedback": {
    "error": {
      "onlight": {
        "value": "#b95319"
      },
      "ondark": {
        "value": "#ff8027"
      },
      "background": {
        "onlight": {
          "value": "#ffece0"
        },
        "ondark": {
          "value": "#561701"
        }
      }
    },
    "warning": {
      "onlight": {
        "value": "#fed60e"
      },
      "ondark": {
        "value": "#fed60e"
      },
      "background": {
        "onlight": {
          "value": "#fff9de"
        },
        "ondark": {
          "value": "#4b3f04"
        }
      }
    },
    "information": {
      "onlight": {
        "value": "#006fc1"
      },
      "ondark": {
        "value": "#0089ec"
      },
      "background": {
        "onlight": {
          "value": "#e3f2fd"
        },
        "ondark": {
          "value": "#002c4d"
        }
      }
    },
    "success": {
      "onlight": {
        "value": "#008331"
      },
      "ondark": {
        "value": "#00b845"
      },
      "background": {
        "onlight": {
          "value": "#dcf5e6"
        },
        "ondark": {
          "value": "#003514"
        }
      }
    }
  },
  "interactive": {
    "active": {
      "onlight": {
        "value": "#6f7171"
      },
      "ondark": {
        "value": "#a7a7a7"
      }
    },
    "disabled": {
      "onlight": {
        "value": "#d8dada"
      },
      "ondark": {
        "value": "#333333"
      }
    },
    "scrollthumb": {
      "onlight": {
        "value": "#6f7171"
      },
      "ondark": {
        "value": "#a7a7a7"
      },
      "hover": {
        "onlight": {
          "value": "#333333"
        },
        "ondark": {
          "value": "#d8dada"
        }
      }
    },
    "scrolltrack": {
      "onlight": {
        "value": "#d8dada"
      },
      "ondark": {
        "value": "#333333"
      }
    }
  },
  "elements": {
    "primary": {
      "onlight": {
        "value": "#000000"
      },
      "ondark": {
        "value": "#ffffff"
      },
      "inverse": {
        "onlight": {
          "value": "#ffffff"
        },
        "ondark": {
          "value": "#000000"
        }
      }
    },
    "secondary": {
      "onlight": {
        "value": "#6f7171"
      },
      "ondark": {
        "value": "#a7a7a7"
      }
    },
    "brandhighlight": {
      "value": "#ee0000"
    },
    "lowcontrast": {
      "onlight": {
        "value": "#d8dada"
      },
      "ondark": {
        "value": "#333333"
      }
    }
  },
  "dataviz": {
    "orange200": {
      "value": "#ffcaaa"
    },
    "orange300": {
      "value": "#ffa46d"
    },
    "orange400": {
      "value": "#ff8027"
    },
    "orange600": {
      "value": "#b95319"
    },
    "orange900": {
      "value": "#732706"
    },
    "yellow200": {
      "value": "#fff4bc"
    },
    "yellow300": {
      "value": "#ffe97a"
    },
    "yellow400": {
      "value": "#fed60e"
    },
    "yellow600": {
      "value": "#bc9f0a"
    },
    "yellow900": {
      "value": "#635305"
    },
    "blue200": {
      "value": "#aad8f9"
    },
    "blue300": {
      "value": "#4aabf2"
    },
    "blue400": {
      "value": "#0089ec"
    },
    "blue600": {
      "value": "#006fc1"
    },
    "blue900": {
      "value": "#003e6c"
    },
    "green200": {
      "value": "#a4e6bd"
    },
    "green300": {
      "value": "#63d48e"
    },
    "green400": {
      "value": "#00b845"
    },
    "green600": {
      "value": "#008331"
    },
    "green900": {
      "value": "#004b1c"
    },
    "pink200": {
      "value": "#febfe8"
    },
    "pink300": {
      "value": "#fc89d5"
    },
    "pink400": {
      "value": "#fb42bc"
    },
    "pink600": {
      "value": "#b9318b"
    },
    "pink900": {
      "value": "#671b4e"
    },
    "purple200": {
      "value": "#edb9fb"
    },
    "purple300": {
      "value": "#e084f9"
    },
    "purple400": {
      "value": "#ce3df5"
    },
    "purple600": {
      "value": "#84279e"
    },
    "purple900": {
      "value": "#461553"
    }
  },
  "badges": {
    "background": {
      "red": {
        "onlight": {
          "value": "#ee0000"
        },
        "ondark": {
          "value": "#ee0000"
        }
      },
      "orange": {
        "onlight": {
          "value": "#b95319"
        },
        "ondark": {
          "value": "#ff8027"
        }
      },
      "green": {
        "onlight": {
          "value": "#008331"
        },
        "ondark": {
          "value": "#00b845"
        }
      },
      "blue": {
        "onlight": {
          "value": "#006fc1"
        },
        "ondark": {
          "value": "#0089ec"
        }
      },
      "yellow": {
        "onlight": {
          "value": "#fed60e"
        },
        "ondark": {
          "value": "#fed60e"
        }
      },
      "white": {
        "onlight": {
          "value": "#ffffff"
        },
        "ondark": {
          "value": "#ffffff"
        }
      },
      "black": {
        "onlight": {
          "value": "#000000"
        },
        "ondark": {
          "value": "#000000"
        }
      }
    }
  }
}